<template>
  <div class="rollbox" :class="{ mbcenter: isMinDevice }">
    <!-- roll房列表 -->
    <div class="commercial">
      <img class="w100" :src="onlineUrl + finalFuncImgs[8]" alt="" />
    </div>
    <div class="listbox">
      <div class="tab point" :class="{ mb_tab: isMinDevice }">
        <div
          class="tabitem"
          v-for="(v, i) in tablist"
          :key="i"
          :class="status == v.id ? 'actabitem ' : ''"
          @click="tabchange(v)"
        >
          {{ v.name }}
        </div>
      </div>
      <div>
        <div
          :class="{ mb_list: isMinDevice }"
          @click="$router.push({ path: '/rollDetail', query: { id: item.id } })"
          v-for="item in rollList"
          :key="item.id"
          class="rolllist"
        >
          <div class="imgbox" :class="{ mb_imgbox: isMinDevice }">
            <img :src="onlineUrl + item?.image" alt="" />
          </div>
          <div class="infobox" v-if="!isMinDevice">
            <div style="margin-right: 0.9rem">
              <div class="title">
                {{ item?.name }}
              </div>
              <div class="content">
                <div class="item">
                  Number of prizes：{{ item?.skins_count }}
                </div>
                <div class="item">
                  Number of participants:{{ item?.join_user_count }}
                </div>
                <div class="item">Prize value：$ {{ item?.total_value }}</div>
              </div>

              <div class="joinbtnbox">
                <div class="endtimebox">End time：{{ item.end_time }}</div>
                <div
                  v-if="item.status !== '1'"
                  @click.stop="passwordShowOnClick(item)"
                  class="joinbtn point"
                >
                  Join
                </div>
              </div>
            </div>
          </div>
          <div v-else class="mb_infobox">
            <div class="mb_title">{{ item?.name }}</div>
            <div class="mb_item">Number of prizes：{{ item?.skins_count }}</div>
            <div class="mb_item">Prize value：$ {{ item?.total_value }}</div>
            <div class="flex-s" style="margin-top: 0.5rem">
              <div class="mb_endtimebox">End time：{{ item.end_time }}</div>
              <div class="mb_usernums">
                <img src="../../assets/images/pc/mb-roll-nums.png" alt="" />{{
                  item?.join_user_count
                }}
              </div>
            </div>
            <div v-if="item.status !== '1'" class="mb_joinbtnbox">
              <div
                @click.stop="passwordShowOnClick(item)"
                class="mb_joinbtn point"
              >
                Join
              </div>
            </div>
          </div>
          <div
            v-if="item.status === '1'"
            class="tipsshow"
            :class="{ mb_tipsshow: isMinDevice }"
          >
            Finished
          </div>
        </div>
      </div>
    </div>
    <van-overlay :show="passwordShow">
      <div class="namebox" :class="{ mb_namebox: isMinDevice }">
        <div class="title" :class="{ mb_nametitle: isMinDevice }">
          PassWord
          <img
            class="point"
            src="@/assets/images/pc/pc-home-icon10.png"
            alt=""
            @click="passwordShow = false"
          />
        </div>
        <div class="flex-c" :class="{ mb_putbox: isMinDevice }">
          <input
            class="putbox"
            type="text"
            placeholder="Please enter the room code"
            v-model="password"
          />
        </div>
        <div class="flex-c">
          <div
            class="amend2 point"
            :class="{ mb_amen: isMinDevice }"
            @click="joinRollOnClick(true)"
          >
            Join
          </div>
        </div>
      </div>
    </van-overlay>
  </div>
</template>

<script>
import { getRollListApi, JoinRollApi } from "@/network/api.js";
import { mapState, mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(["finalFuncImgs"]),
    ...mapState(["onlineUrl", "isMinDevice"]),
  },
  data() {
    return {
      tablist: [
        {
          name: "Under way",
          id: 0,
        },
        {
          name: "Finished",
          id: 1,
        },
      ],
      Finished: false,
      joioshow: true,
      page: 1,
      status: 0,
      rollList: [],
      passwordShow: false,
      password: "",
      choosedItem: "",
    };
  },
  watch: {
    status: {
      handler() {
        this.page = 1;
        this.getRollListFun();
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    passwordShowOnClick(item) {
      this.choosedItem = item;
      this.joinRollOnClick();
    },
    async joinRollOnClick(isPwd = false) {
      this.$router.push({
        path: "/rollDetail",
        query: { id: this.choosedItem?.id },
      });
    },
    async getRollListFun() {
      const params = {
        page: this.page,
        status: this.status,
      };
      const res = await getRollListApi(params);
      this.rollList = res?.data?.Items;
      console.log(123, res);
    },
    tabchange(v) {
      this.status = v.id;
      switch (v.id) {
        case 1:
          (this.joioshow = true), (this.Finished = false);
          break;
        case 2:
          (this.joioshow = false), (this.Finished = true);
          break;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.rollbox {
  width: 14.74rem;
  margin: 0 auto;
  min-height: 80vh;
  .commercial {
    width: 100%;
    // height: 0.8rem;
    // background-color: #dfdfdf;
    margin-top: 0.9rem;
    margin-bottom: 0.75rem;
  }

  .listbox {
    width: 100%;

    .tab {
      width: 3.8rem;
      height: 0.53rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 0.45rem;

      .tabitem {
        width: 1.76rem;
        height: 0.53rem;
        text-align: center;
        line-height: 0.53rem;
        background: #0076ff;
        font-size: 0.18rem;
        font-family: PingFang SC-Semibold, PingFang SC;
        font-weight: 600;
        color: #ffffff;
        border-radius: 0.04rem;
      }

      .actabitem {
        background: #003879;
      }
    }
    .mb_tab {
      width: 9rem;
      height: 1.5rem;
      .tabitem {
        width: 4rem;
        height: 1.5rem;
        text-align: center;
        line-height: 1.5rem;
        background: #0076ff;
        font-size: 0.6rem;
        font-family: PingFang SC-Semibold, PingFang SC;
        font-weight: 600;
        color: #ffffff;
        border-radius: 0.1rem;
      }
      .actabitem {
        background: #003879;
      }
    }

    .rolllist {
      width: 100%;
      height: 2.67rem;
      background: url("../../assets/images/pc/pc-roll-cardbg.png") no-repeat;
      background-size: 100% 103%;
      margin-bottom: 0.25rem;
      display: flex;
      position: relative;

      .imgbox {
        width: 2.56rem;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          display: block;
          // width: 1.86rem;
          height: 2.15rem;
        }
      }
      .mb_imgbox {
        width: 4.6rem;
        height: 5rem;
        margin: 0.5rem;
        img {
          height: 100%;
        }
      }

      .infobox {
        width: 12.18rem;
        height: 100%;

        .title {
          width: 12rem;
          height: 0.33rem;
          font-size: 0.42rem;
          font-family: PingFang SC-Medium, PingFang SC;
          font-weight: 500;
          color: #000000;
          line-height: 0.33rem;
          margin-top: 0.3rem;
        }

        .content {
          width: 100%;
          height: 1.1rem;
          display: flex;
          justify-content: flex-start;
          align-items: center;

          .item {
            width: 2.94rem;
            height: 0.5rem;
            background: #ffffff;
            box-shadow: 0 0 0.04rem 0 rgba($color: #000000, $alpha: 0.2);
            margin-right: 0.6rem;
            font-size: 0.18rem;
            font-family: PingFang SC-Semibold, PingFang SC;
            font-weight: 600;
            color: #000000;
            line-height: 0.5rem;
            text-align: center;
          }
        }
        .endtimebox {
          font-size: 0.2rem;
          font-family: PingFang SC, PingFang SC;
          font-weight: 400;
          color: #000000;
        }

        .joinbtnbox {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-top: 0.2rem;
          .joinbtn {
            width: 2rem;
            height: 0.5rem;
            background: #0076ff;
            border-radius: 0.06rem;
            font-size: 0.18rem;
            font-family: PingFang SC-Semibold, PingFang SC;
            font-weight: 600;
            color: #ffffff;
            text-align: center;
            line-height: 0.5rem;
          }
          .endbtn {
            width: 2rem;
            height: 0.5rem;
            background: #5178a5;
            border-radius: 0.06rem;
            font-size: 0.18rem;
            font-family: PingFang SC-Semibold, PingFang SC;
            font-weight: 600;
            color: #ffffff;
            text-align: center;
            line-height: 0.5rem;
          }
        }
      }
      .mb_infobox {
        width: 11rem;
        margin-top: 0.5rem;

        .mb_title {
          width: 100%;
          height: 0.9rem;
          font-size: 0.6rem;
          font-family: PingFang SC, PingFang SC;
          font-weight: 800;
          color: #000000;
        }
        .mb_item {
          width: 7.8rem;
          height: 1rem;
          font-size: 0.5rem;
          background: #ffffff;
          box-shadow: 0 0.1rem 0.2rem 0 rgba(0, 0, 0, 0.1);
          margin-top: 0.5rem;
          line-height: 1rem;
          padding-left: 0.5rem;
        }
        .mb_endtimebox {
          font-size: 0.55rem;
          white-space: nowrap;
        }
        .mb_usernums {
          width: 100%;
          display: flex;
          justify-content: flex-end;
          font-size: 0.6rem;
          font-family: PingFang SC, PingFang SC;
          font-weight: 500;
          color: #535353;
          img {
            width: 0.7rem;
            height: 0.7rem;
            margin-right: 0.4rem;
          }
        }
        .mb_joinbtnbox {
          position: absolute;
          bottom: 0.5rem;
          left: 3%;
          width: 16rem;
          .mb_joinbtn {
            width: 100%;
            height: 1.2rem;
            background: #0076ff;
            color: #fff;
            text-align: center;
            line-height: 1.2rem;
            font-size: 0.7rem;
            font-family: PingFang SC, PingFang SC;
            font-weight: 600;
            border-radius: 0.12rem;
          }
        }
      }

      .tipsshow {
        width: 100%;
        height: 2.67rem;
        position: absolute;
        top: 0;
        right: 0;
        background: rgba($color: #000000, $alpha: 0.5);
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 0.5rem;
        font-family: PingFang SC-Semibold, PingFang SC;
        font-weight: 600;
        color: #ffffff;
      }
      .mb_tipsshow {
        height: 7.6rem;
        font-size: 0.7rem;
        border-radius: 0.3rem;
      }
    }
    .mb_list {
      width: 17.152rem;
      height: 7.6rem;
      background: url("../../assets/images/pc/mb-roll-cardbg.png") no-repeat;
      background-size: 100% 103%;
      position: relative;
    }
  }
  .namebox {
    width: 8.5rem;
    height: 3.17rem;
    border-radius: 0.12rem;
    background: #fff;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    .title {
      width: 100%;
      height: 0.8rem;
      background: #002550;
      text-align: center;
      line-height: 0.8rem;
      font-size: 0.28rem;
      font-family: PingFang SC-Semibold, PingFang SC;
      font-weight: 600;
      color: #ffffff;
      border-radius: 0.12rem 0.12rem 0 0;
      position: relative;
      margin-bottom: 0.3rem;

      img {
        display: block;
        width: 0.35rem;
        height: 0.35rem;
        position: absolute;
        top: 0.2rem;
        right: 0.2rem;
      }
    }
    .putbox {
      width: 6.9rem;
      height: 0.64rem;
      margin: 0.2rem auto;
      border: 0.01rem solid #b7b7b7;
      text-decoration: none;
      text-align: center;
      font-size: 0.28rem;
      font-family: PingFang SC, PingFang SC;
      font-weight: 400;
      color: #848484;
    }
    .mb_putbox {
      height: 2.9rem;
      background: #fff;
      input {
        width: 15rem;
        height: 1.2rem;
        font-size: 0.6rem;
      }
    }
    .amend2 {
      // width: 3.18rem;
      // height: 0.6rem;
      border-radius: 0.04rem;
      background: #0076ff;
      font-size: 0.2rem;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 500;
      color: #ffffff;
      // line-height: 0.6rem;
      text-align: center;
      padding: 0.0836rem 0.512rem;
    }
    .mb_amen {
      width: 4.7rem;
      height: 1.07rem;
      font-size: 0.7rem;
      line-height: 1.07rem;
      margin-bottom: 0.5rem;
    }
  }
  .mb_namebox {
    width: 17rem;
    height: unset;
    .mb_nametitle {
      height: 1.5rem;
      font-size: 0.7rem;
      line-height: 1.5rem;
      img {
        width: 0.8rem;
        height: 0.8rem;
      }
    }
  }
}
.mbcenter {
  width: 17.1rem;
}
</style>
